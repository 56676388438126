import React from 'react';
import dynamic from 'next/dynamic';
import { useFeatures } from '@/controllers/features/features.hooks/useFeatures';
import { useSubDomainContext } from '@/controllers/subDomain/subDomain.hooks/useSubDomainContext';
import { SubDomains } from '@/constants/subdomains';

const SupportSocialMediasCollapsable = dynamic(
  () => import('@/components/common/SupportSociaMedias')
    .then((mod) => mod.SupportSocialMediasCollapsable),
);

const HelpCrunchScript = dynamic(
  () => import('@/components/services/HelpCrunch/HelpCrunchScript')
    .then((mod) => mod.HelpCrunchScript),
);

export const LandingSupportButton = () => {
  const features = useFeatures();
  const { subDomain } = useSubDomainContext();

  const shouldShowHelpCrunch = (
    features.isEnabled(features.HelpCrunchIntegration)
    && subDomain === SubDomains.pl
  );

  if (shouldShowHelpCrunch) {
    return <HelpCrunchScript />;
  }

  return <SupportSocialMediasCollapsable shouldShowScrollToTop={false} />;
};
